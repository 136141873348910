define("discourse/plugins/swapd-topic-bump/discourse/components/modal/topic-bump-modal", ["exports", "@ember/component", "I18n", "discourse/lib/ajax", "@ember/object", "@ember/service", "@ember/template-factory"], function (_exports, _component, _I18n, _ajax, _object, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    
  <DModal @title={{this.title}} @closeModal={{@closeModal}} class="close-topic-modal">
    <:body>
      <p>
        {{#if this.next_autobump}}
          {{{i18n "topic_bump.auto_bump.modal_text_enabled"}}}
        {{else}}
          {{{i18n "topic_bump.auto_bump.modal_text_disabled"}}}
      {{/if}}
      </p>
    </:body>
    <:footer>
      {{#if this.next_autobump}}
        <DButton
          @action={{action "disableAutobump"}}
          class="btn-primary"
          @label="topic_bump.auto_bump.modal_button_disable"
        />
      {{else}}
        <DButton
          @action={{action "enableAutobump"}}
          class="btn-primary"
          @label="topic_bump.auto_bump.modal_button_enable"
        />
        <DButton
          @action={{action "bumpOnce"}}
          class="btn-primary"
          @label="topic_bump.auto_bump.modal_button_bump"
        />
      {{/if}}
      <DButton
        @action={{action "close"}}
        @label="topic_bump.auto_bump.modal_button_cancel"
      />
  
    </:footer>
  </DModal>
  */
  {
    "id": "DRvrEtsv",
    "block": "[[[1,\"\\n\"],[8,[39,0],[[24,0,\"close-topic-modal\"]],[[\"@title\",\"@closeModal\"],[[30,0,[\"title\"]],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n\"],[41,[30,0,[\"next_autobump\"]],[[[1,\"        \"],[2,[28,[37,4],[\"topic_bump.auto_bump.modal_text_enabled\"],null]],[1,\"\\n\"]],[]],[[[1,\"        \"],[2,[28,[37,4],[\"topic_bump.auto_bump.modal_text_disabled\"],null]],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n\"],[41,[30,0,[\"next_autobump\"]],[[[1,\"      \"],[8,[39,6],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[28,[37,7],[[30,0],\"disableAutobump\"],null],\"topic_bump.auto_bump.modal_button_disable\"]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[39,6],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[28,[37,7],[[30,0],\"enableAutobump\"],null],\"topic_bump.auto_bump.modal_button_enable\"]],null],[1,\"\\n      \"],[8,[39,6],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[28,[37,7],[[30,0],\"bumpOnce\"],null],\"topic_bump.auto_bump.modal_button_bump\"]],null],[1,\"\\n\"]],[]]],[1,\"    \"],[8,[39,6],null,[[\"@action\",\"@label\"],[[28,[37,7],[[30,0],\"close\"],null],\"topic_bump.auto_bump.modal_button_cancel\"]],null],[1,\"\\n\\n  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\":body\",\"p\",\"if\",\"i18n\",\":footer\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/swapd-topic-bump/discourse/components/modal/topic-bump-modal.hbs",
    "isStrictMode": false
  });
  async function performAction(action, topic, dlg) {
    try {
      let result = await (0, _ajax.ajax)(`/t/${topic.id}/${action}`, {
        type: "POST"
      }).then(data => {
        if (data.msg) {
          dlg.alert(_I18n.default.t("topic_bump.auto_bump.performed_" + action));
        }
      });
    } catch (e) {
      const xhr = e.xhr || e.jqXHR;
      if (xhr && xhr.responseJSON) {
        if (xhr.responseJSON.error_type == 'rate_limit') {
          dlg.alert(_I18n.default.t("topic_bump.bump_topic_button.rate_limit_error"));
        }
        if (xhr.responseJSON.error_type == 'invalid_access') {
          dlg.alert(_I18n.default.t("topic_bump.bump_topic_button.error"));
        }
      }
      dlg.alert(_I18n.default.t("topic_bump.bump_topic_button.error"));
    }
  }
  class TopicBumpModal extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.inject]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    init() {
      super.init(...arguments);
      this.set("title", this.model.topic.next_autobump ? _I18n.default.t("topic_bump.auto_bump.modal_title_enabled") : _I18n.default.t("topic_bump.auto_bump.modal_title_disabled"));
      this.set("next_autobump", this.model.topic.next_autobump);
    }
    enableAutobump() {
      performAction("autobump_enable", this.model.topic, this.dialog);
      this.model.topic.next_autobump = 1;
      this.closeModal();
    }
    static #_2 = (() => dt7948.n(this.prototype, "enableAutobump", [_object.action]))();
    disableAutobump() {
      performAction("autobump_disable", this.model.topic, this.dialog);
      this.model.topic.next_autobump = false;
      this.closeModal();
    }
    static #_3 = (() => dt7948.n(this.prototype, "disableAutobump", [_object.action]))();
    bumpOnce() {
      performAction("bump", this.model.topic, this.dialog);
      this.closeModal();
    }
    static #_4 = (() => dt7948.n(this.prototype, "bumpOnce", [_object.action]))();
    close() {
      this.closeModal();
    }
    static #_5 = (() => dt7948.n(this.prototype, "close", [_object.action]))();
  }
  _exports.default = TopicBumpModal;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TopicBumpModal);
});