define("discourse/plugins/swapd-topic-bump/discourse/templates/connectors/category-custom-settings/topic-bump-settings", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.siteSettings.topic_bump_enabled}}
    <h3>{{i18n "topic_bump.title"}}</h3>
    <section class="field">
      <div class="allow-topic-bump">
        <label class="checkbox-label">
          {{input
            type="checkbox"
            checked=this.category.custom_fields.allow_topic_bump
          }}
          {{i18n "topic_bump.allow_topic_bump"}}
        </label>
      </div>
    </section>
  {{/if}}
  */
  {
    "id": "1jnpLN5L",
    "block": "[[[41,[30,0,[\"siteSettings\",\"topic_bump_enabled\"]],[[[1,\"  \"],[10,\"h3\"],[12],[1,[28,[35,2],[\"topic_bump.title\"],null]],[13],[1,\"\\n  \"],[10,\"section\"],[14,0,\"field\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"allow-topic-bump\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n        \"],[1,[28,[35,6],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"category\",\"custom_fields\",\"allow_topic_bump\"]]]]]],[1,\"\\n        \"],[1,[28,[35,2],[\"topic_bump.allow_topic_bump\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"h3\",\"i18n\",\"section\",\"div\",\"label\",\"input\"]]",
    "moduleName": "discourse/plugins/swapd-topic-bump/discourse/templates/connectors/category-custom-settings/topic-bump-settings.hbs",
    "isStrictMode": false
  });
});